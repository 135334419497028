import gql from 'graphql-tag'
import {PAIR_STATUS_REQUIREMENT_MODEL_FRAGMENT} from "@/modules/graphql/PairStatusRequirementModelFragment";

export const ACTIVITY_MODEL_FRAGMENT = gql`
  ${PAIR_STATUS_REQUIREMENT_MODEL_FRAGMENT}

  fragment ActivityModelFields on ActivityModel {
      id
      action
      consignment
      identifier
      requirements {
         ...PairStatusRequirementModelFields
      }
      statusType
      statuses {
          state
          type
      }
  }
`
