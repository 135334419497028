import gql from 'graphql-tag'

export const VEHICLE_MODEL_FRAGMENT = gql`
  fragment VehicleModelFields on VehicleModel {
    id
    licensePlate
    description
    type
  }
`
