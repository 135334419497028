import gql from 'graphql-tag'
import {ADDRESS_MODEL_FRAGMENT} from "@/modules/graphql/AddressModelFragment";
import {ACTIVITY_MODEL_FRAGMENT} from "@/modules/graphql/ActivityModelFragment";

export const STOP_MODEL_FRAGMENT = gql`
  ${ACTIVITY_MODEL_FRAGMENT}
  ${ADDRESS_MODEL_FRAGMENT}
  
  fragment StopModelFields on StopModel {
    id
    identifier
    eta
    etd
    address {
      ...AddressModelFields
    }
    activities {
      ...ActivityModelFields
    }
    instructions
  }
`
