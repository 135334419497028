








import {Component, Mixins} from 'vue-property-decorator'
import Documents from '@/modules/shipments/views/detail/components/Documents.vue'
import ECMR from '@/modules/shipments/views/detail/components/ECMR.vue'
import PlanningService from "@/modules/planning/PlanningService";
import BaseDataTable from "@/general/components/general/BaseDataTable.vue";
import InfoCard from "@/general/components/common/InfoCard.vue";
import PlanningStops from "@/modules/planning/views/PlanningStops.vue";
import {GetAbout_GetAbout} from "@/generated/GetAbout";
import {getECMRSFromDatabase, saveECMRSToDatabase} from "@/general/services/DatabaseService";
import ECMRService from "@/modules/ecmrs/ECMRService";

@Component({
  components: {
    PlanningStops,
    InfoCard,
    BaseDataTable,
    Documents,
    ECMR
  }
})
export default class ScanListPlannings extends Mixins(PlanningService, ECMRService) {

  created() {
    this.fetchPlanning()
  }

  fetchPlanning() {
    this.getPlanningByIdOrAccessCode(parseInt(this.$route.params.id)).then(result => {
      this.$store.dispatch('transport/setPlanning', result)
        this.fetchECMRS(result.id)
        this.$store.state.transport.newEcmrs = true
    })
    this.$store.commit('loading/set', false)
  }

  fetchECMRS(planningId: number) {
    if (this.$store.state.systemSettings.find((setting: GetAbout_GetAbout) => setting.key === "EcmrEnabled")?.value === 'true') {
      this.getECMRSByPlanning(planningId).then(result => {
        result.forEach(item => {
          this.$store.state.transport.ecmrs.set(item.key, item.value)
          this.$store.state.transport.newEcmrs = true
        })
        saveECMRSToDatabase(this.$store.state.transport.ecmrs)
      }).catch(() => {
        getECMRSFromDatabase().then(result => {
          if (result) {
            this.$store.commit('snackbar/show', {
              color: 'error',
              text: 'Error retrieving ECMRS, showing offline version'
            })
            this.$store.state.transport.ecmrs = result
          } else {
            this.$store.commit('snackbar/show', {
              color: 'error',
              text: 'Error retrieving ECMRS, no offline version available'
            })
          }
        })
      })
    }
  }
}
