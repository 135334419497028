import { Component, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import {FetchResult} from "apollo-link";
import {CreateStatuses} from "@/generated/CreateStatuses";
import {CreateStatusesAccessCode} from "@/generated/CreateStatusesAccessCode";
import {BundledStatusesCreateModel, BundledStatusesIDCreateModel} from "@/generated/globalTypes";

@Component
export default class StatusService extends Vue {
  private createIDStatusQuery = gql`
    mutation CreateIDStatuses($bundledStatusesCreateModel: BundledStatusesIDCreateModel!) {
      CreateIDStatuses(a0: $bundledStatusesCreateModel)
    }
  `

  private createIDStatusQueryAccessCode = gql`
    mutation CreateIDStatusesAccessCode($bundledStatusesCreateModel: BundledStatusesIDCreateModel!) {
      CreateIDStatusesAccessCode(a0: $bundledStatusesCreateModel)
    }
  `

  private createStatusQuery = gql`
    mutation CreateStatuses($bundledStatusesCreateModel: BundledStatusesCreateModel!) {
      CreateStatuses(a0: $bundledStatusesCreateModel)
    }
  `

  private createStatusQueryAccessCode = gql`
    mutation CreateStatusesAccessCode($bundledStatusesCreateModel: BundledStatusesCreateModel!) {
      CreateStatusesAccessCode(a0: $bundledStatusesCreateModel)
    }
  `

  createStatus1(createStatus: BundledStatusesIDCreateModel): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.$apollo.mutate({
        mutation: this.$route.query.accessCode ? this.createIDStatusQueryAccessCode : this.createIDStatusQuery,
        variables: {
          bundledStatusesCreateModel: createStatus
        },
      }).then((result: FetchResult<CreateStatuses | CreateStatusesAccessCode>) => {
        //@ts-ignore
        resolve(this.$route.query.accessCode ? result.data?.CreateStatusesAccessCode : result.data?.CreateStatuses)
      }).catch(error => {
        reject(error)
      })
    })
  }

  createStatus1Los(createStatus: BundledStatusesCreateModel): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.$apollo.mutate({
        mutation: this.$route.query.accessCode ? this.createStatusQueryAccessCode : this.createStatusQuery,
        variables: {
          bundledStatusesCreateModel: createStatus
        },
      }).then((result: FetchResult<CreateStatuses | CreateStatusesAccessCode>) => {
        //@ts-ignore
        resolve(this.$route.query.accessCode ? result.data?.CreateStatusesAccessCode : result.data?.CreateStatuses)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
