import {
    GetConsignment_GetConsignment,
    GetConsignment_GetConsignment_actors_pickup_address
} from "@/generated/GetConsignment";
import {
    Action,
    BundledStatusCreateModel,
    DimensionCreateModel,
    DocumentType,
    HandoverCreateModel,
    MapStringIntInput,
    MapStringStringInput, PersonCreateModel,
    PlanningDataCreateModel,
    Requirement,
    RequirementLevel,
    StatusState,
    StatusType
} from "@/generated/globalTypes";
import {MapType} from "@/general/models/MapType";
import {CreateStatusData, Data, Emballage} from "@/modules/scan/components/CreateStatusData";
import {Component, Vue} from 'vue-property-decorator'
import {
    GetPlanning_GetPlanning,
    GetPlanning_GetPlanning_consignments,
    GetPlanning_GetPlanning_stops,
    GetPlanning_GetPlanning_stops_activities,
    GetPlanning_GetPlanning_stops_activities_requirements,
} from "@/generated/GetPlanning";
import { ListPackagingTypes_ListPackagingTypes } from "@/generated/ListPackagingTypes";
import { v4 as uuidv4 } from 'uuid';

@Component
export class PlanningUtil extends Vue {
    crossDockStatusTypes = [StatusType.CROSSDOCK_PICKUP, StatusType.CROSSDOCK_DELIVERY]

    visibleRequirementOrder = [
        Requirement.SPECIFY_DECLINED_STATE,
        Requirement.IMAGE,
        Requirement.DOCUMENT,
        Requirement.MODIFY_SHELVES_EXTENSIONS,
        Requirement.MESSAGE,
        Requirement.SCAN,
        Requirement.EMBALLAGE,
        Requirement.EMBALLAGE_PICKED_UP, 
        Requirement.EMBALLAGE_DELIVERED,
        Requirement.TEMPERATURE,
        Requirement.SIGNATURE
    ]

    consolidateStopContainers(consignment: GetConsignment_GetConsignment[]): string {
        let map: Map<string, number> = new Map()

        consignment.flatMap(consignment => consignment.containers).map(container => {
            if (container.type) {
                map = map = this.updateMap(container.type.type!!, Number(container.quantity), map)
            }
        })

        return this.mapToString(map).join(", ")
    }

    consolidateConsignmentContainers(consignment: GetConsignment_GetConsignment): string[] {
        let map: Map<string, number> = new Map()

        // Loop 5 times through the containers so the order is always semi the same
        consignment.containers!!.forEach(container => {
            if (container.type) {
                map = map = this.updateMap(container.type.type!!, Number(container.quantity), map)
            }
        })

        consignment.containers!!.forEach(container => {
            if (container.dimensions) {
                container.dimensions.forEach(dimension => {
                    map = this.updateMap(dimension.unitCode!!, Number(dimension.value), map)
                })
            }
        })

        consignment.containers!!.forEach(container => {
            if (container.properties?.items?.length) {
                container.properties.items.forEach(property => {
                   map = map = this.updateMap(property.key!!, Number(property.value), map)
                })
            }
        })

        consignment.containers!!.forEach(container => {
            container.packets.forEach(packet => {
                if (packet.quantity) {
                    map = map = this.updateMap('colli', Number(packet.quantity), map)
                }
            })
        })

        consignment.containers!!.forEach(container => {
            container.packets.forEach(packet => {
                if (packet.description) {
                    map.set(packet.description, 0)
                }
            })
        })

        return this.mapToString(map)
    }

    private mapToString(map: Map<string, number>): string[] {
        let returnStrings: string[] = []
        map.forEach((value, key) => {
            returnStrings.push(value ? `${value} ${key}` : key)
        })
        return returnStrings
    }

    findReferenceForConsignmentAndActor(consignment: GetConsignment_GetConsignment, action: string): string | null {
        let actor = consignment.actors.find((actor) => actor.company.actorId === consignment.shipment?.actorId)
        return action === 'UNLOAD' && actor ? actor.delivery.identifier : actor!!.pickup.identifier
    }

    isStatusRequirementUnsatisfied(statusData: CreateStatusData, requirements: GetPlanning_GetPlanning_stops_activities_requirements[], field: Requirement, index: number, packagingTypes: ListPackagingTypes_ListPackagingTypes[], consignments: GetPlanning_GetPlanning_consignments[]): boolean {
        let lowercaseField = field.toLowerCase()
        if (requirements.filter(requirement => requirement.level !== RequirementLevel.OPTIONAL).find(req => req.requirement === field)) {
            if (field === Requirement.SIGNATURE) {
                return (!statusData.data[index].documents.map(documentIndex => statusData.documents[documentIndex]).filter(document => document.type === DocumentType.SIGNATURE).length)
            } else if (field === Requirement.IMAGE) {
                return (!statusData.data[index].documents.map(documentIndex => statusData.documents[documentIndex]).filter(document => document.type === DocumentType.IMAGE).length)
            } else if (field === Requirement.DOCUMENT) {
                return (!statusData.data[index].documents.map(documentIndex => statusData.documents[documentIndex]).filter(document => document.type === DocumentType.DOCUMENT).length)
            } else if (field === Requirement.SCAN) {
                return false
            } else if (field === Requirement.EMBALLAGE_DELIVERED) {
                return this.isEmballageUnsatisfied(statusData, index, (statusData.data[index] as MapType)["deliveredEmballage"], packagingTypes, consignments)
            } else if (field === Requirement.EMBALLAGE_PICKED_UP) {
                return this.isEmballageUnsatisfied(statusData, index, (statusData.data[index] as MapType)["pickedUpEmballage"], packagingTypes, consignments)
            } else if (field === Requirement.MESSAGE) {
                return !statusData.data[index].message.replaceAll(this.$t('sign.signAbsence') as string, '').length
            } else if (field === Requirement.FINISH_PLANNING || field === Requirement.ACTIVITY_STARTED) {
                return false
            } else if (field === Requirement.TEMPERATURE) {
                return !statusData.data[index].dimensions.find(dimension => dimension.attributeId === "Temperature")
            } else if (field === Requirement.SPECIFY_DECLINED_STATE) {
                return statusData.data[index].state === StatusState.DECLINED
            } else if (field === Requirement.MANUAL_ACCEPT_CHECK) {
                return false
            } else if (Array.isArray((statusData.data[index] as MapType)[lowercaseField])) {
                return !(statusData.data[index] as MapType)[lowercaseField].length
            } else {
                return !(statusData.data[index] as MapType)[lowercaseField]
            }
        }
        return false
    }

    isEmballageUnsatisfied(statusData: CreateStatusData, index: number, emballage: Emballage[], packagingTypes: ListPackagingTypes_ListPackagingTypes[], consignments: GetPlanning_GetPlanning_consignments[]): boolean {
        if ((statusData.data[index] as MapType).clickedZeroEmballage) return false
        return this.emballageInputUnsatisfied(emballage, packagingTypes, consignments)
    }

    private emballageInputUnsatisfied(emballage: Emballage[], packagingTypes: ListPackagingTypes_ListPackagingTypes[], consignments: GetPlanning_GetPlanning_consignments[]) {
        let returnValue = !emballage.length
        const containerTypes = consignments.flatMap(c => c.containers.map(co => co.type))
        packagingTypes.filter((type) => type.required && containerTypes.some(c => c?.type === type.packagingType)).forEach((type) => {
            const emb = emballage.find(e => e.type === type.packagingType)
            // @ts-ignore
            if (emb === undefined || isNaN(emb.amount) || emb.amount === '') returnValue = true
        })
        return returnValue
    }

    findRequirementInActivityByOptional(activity: GetPlanning_GetPlanning_stops_activities, data: Data, requirement: Requirement, optional: boolean): boolean {
        // Requirements that are required are automatically also optional
        return this.getStatusRequirementsForActivity(activity, data).filter(requirementPair => requirementPair.requirement === requirement &&
            (optional ? true : requirementPair.level !== RequirementLevel.OPTIONAL)).length !== 0
    }

    getActivityPendingRequiredStatusRequirement(activity: GetPlanning_GetPlanning_stops_activities, statusData: CreateStatusData, index: number, packagingTypes: ListPackagingTypes_ListPackagingTypes[], consignments: GetPlanning_GetPlanning_consignments[]): GetPlanning_GetPlanning_stops_activities_requirements[] {
        return this.getStatusRequirementsForActivity(activity, statusData.data[index]).map(requirement => {
            if (this.isStatusRequirementUnsatisfied(statusData, [requirement], requirement.requirement, index, packagingTypes, consignments)) return requirement
            else return null
        }).filter(requirement => requirement) as GetPlanning_GetPlanning_stops_activities_requirements[]
    }

    hasActivityVisibleRequirements(activity: GetPlanning_GetPlanning_stops_activities, data: Data | undefined, optional: boolean): boolean {
        // Check all requirements of a activity and return true if
        // its a status requirement that has to be manually filled in by the user when sending a status
        // If its a statusrequirement not visible for the user we return false

        for (let item of this.getStatusRequirementsForActivity(activity, data)) {
            if ((optional || item.level !== RequirementLevel.OPTIONAL) && this.visibleRequirementOrder.includes(item.requirement)) {
                return true
            }
        }
        return false
    }

    getStatusRequirementsForStop(stop: GetPlanning_GetPlanning_stops, data: Data[]): GetPlanning_GetPlanning_stops_activities_requirements[] {
        return stop.activities.flatMap((activity, index) => this.getStatusRequirementsForActivity(activity, data[index]))
    }

    getStatusRequirementsForActivity(activity: GetPlanning_GetPlanning_stops_activities, data: Data | undefined): GetPlanning_GetPlanning_stops_activities_requirements[] {
        let requirements: GetPlanning_GetPlanning_stops_activities_requirements[] = activity.requirements
        if (data) {
            // Filter out all conditional requirements that are not applicable.
            if (data.state === StatusState.ACCEPTED) {
                requirements = requirements.filter(requirement => requirement.level !== RequirementLevel.REQUIRED_IF_NOT_ACCEPTED)
            }
            if (data.state !== StatusState.DAMAGED) {
                requirements = requirements.filter(requirement => requirement.level !== RequirementLevel.REQUIRED_IF_DAMAGED)
            }
        } else {
            // If we dont know any state yet we should filter out all conditional requirements since we cant verify the condition yet
            requirements = requirements.filter(requirement => requirement.level === RequirementLevel.OPTIONAL || requirement.level === RequirementLevel.REQUIRED)
        }

        // Done for backwards compatability with older requirements using the EMBAlLAGE requirement. Back then we only supported
        // Pickup emballage
        if (requirements.find(requirement => requirement.requirement === Requirement.EMBALLAGE)) {
            requirements.push({ requirement: Requirement.EMBALLAGE_PICKED_UP, level: RequirementLevel.REQUIRED, text: null, global: true, __typename: "StatusRequirementModel"})
            requirements = requirements.filter(requirement => requirement.requirement !== Requirement.EMBALLAGE)
        }

        return requirements
    }

    createBundledStatusCreateModel(
        documents: number[] | null,
        deliveredEmballage: MapStringIntInput | null,
        pickedUpEmballage: MapStringIntInput | null,
        handover: HandoverCreateModel,
        message: string | null,
        type: StatusType,
        planningData: PlanningDataCreateModel | null,
        mutations: MapStringStringInput | null,
        location: string | null,
        person: PersonCreateModel | null,
        dimensions: DimensionCreateModel[],
        state: StatusState
    ) {
        let status: BundledStatusCreateModel = {
            documents: documents,
            deliveredEmballage: deliveredEmballage,
            pickedUpEmballage: pickedUpEmballage,
            handover: handover,
            identifier: uuidv4(),
            message: message,
            location: location,
            person: (person?.name || person?.message) ? person : null,
            type: type,
            planningData: planningData,
            mutations: mutations,
            dimensions: dimensions,
            state: state
        }
        return status
    }

    getActivityIdentifierLine(crossdock: boolean, action: String, consignment: GetConsignment_GetConsignment, prefix: boolean = true, company: boolean = true, sender: string = 'tradelane.fields.sender', receiver: string = 'tradelane.fields.receiver', includeCrossdockComissionedBy: boolean = true): string {
        return crossdock 
            ? this.getActivityIdentifierLineCrossDock(includeCrossdockComissionedBy, consignment, sender, receiver)
            : this.getActivityIdentifierLineNoCrossDock(action, consignment, prefix, company, sender, receiver)
    }

    getActivityIdentifierLineNoCrossDock(action: String, consignment: GetConsignment_GetConsignment, prefix: boolean = true, company: boolean = true, sender: string = 'tradelane.fields.sender', receiver: string = 'tradelane.fields.receiver'): string {
        const pf = prefix && company ? (action === 'UNLOAD' ? this.$t('for') + ' ' : this.getComssionedByAbbr() + ' ') : ''
        const c = company ? this.preventEmpty(consignment.sender.name) + ' | ' : ''
        const pickedUpOrDelivery = (action === 'UNLOAD'
            ? (this.$t(sender) + ': ' + this.appendAddressIdentifier(consignment.sender.address))
            : (this.$t(receiver) + ': ' + this.appendAddressIdentifier(consignment.receiver.address))
        )
        return pf + c + pickedUpOrDelivery + this.getUnloadDate(consignment)
    }

    getActivityIdentifierLineCrossDock(includeCrossdockComissionedBy: boolean, consignment: GetConsignment_GetConsignment, sender: string = 'tradelane.fields.sender', receiver: string = 'tradelane.fields.receiver'): string {
        return [
            includeCrossdockComissionedBy ? (this.getComssionedByAbbr() + ' ' + this.appendAddressIdentifier(consignment.receiver.address)) : undefined,
            this.$t(sender) + ' ' + this.appendAddressIdentifier(consignment.actors[0].pickup.address),
            this.$t(receiver) + ' ' + this.appendAddressIdentifier(consignment.actors[0].delivery.address) + this.getUnloadDate(consignment)
        ].filter(value => value).join(" | ")
    }

    findConsignmentRequirements(stop: GetPlanning_GetPlanning_stops, statusData: CreateStatusData, packagingTypes: ListPackagingTypes_ListPackagingTypes[], consignments: GetPlanning_GetPlanning_consignments[]): GetPlanning_GetPlanning_stops_activities_requirements[][] {
        return stop.activities.map((activity, index) => this.getActivityPendingRequiredStatusRequirement(activity, statusData, Number(index), packagingTypes, consignments))
    }

    getUnloadAddress(planning: GetPlanning_GetPlanning, activity: GetPlanning_GetPlanning_stops_activities): string | null | undefined {
        if (activity.action === Action.LOAD) {
            return planning.stops.find(stop => stop.activities.find(stopActivity => activity.consignment && stopActivity.consignment === activity.consignment && stopActivity.action === Action.UNLOAD))?.address?.name
        } else null
    }

    private preventEmpty(stringToCheck: string): string {
        return stringToCheck ? stringToCheck : 'Unknown'
    }

    private appendAddressIdentifier(address: GetConsignment_GetConsignment_actors_pickup_address) {
        return this.preventEmpty(address.name!!) + ' - ' + address.city
    }

    private getComssionedByAbbr(): string {
        return this.$t('commissioned_by').toString()
            .split(' ')
            .map((string) => string.charAt(0))
            .join('.')
            .toLowerCase()
    }

    private getUnloadDate(consignment: GetConsignment_GetConsignment): string {
        return (isNaN(Date.parse(consignment.actors[0].delivery.eta)) ? '' : ` - ${new Date(consignment.actors[0].delivery.eta).toLocaleString("nl-NL", { day: '2-digit', month: '2-digit', year: 'numeric' })}`)
    }

    private updateMap(key: string, value: number, map: Map<string, number>): Map<string, number> {
        return map!!.set(key, map!!.get(key) ? Number((map!!.get(key)!! + value).toFixed(2)) : value)
    }
}