import gql from 'graphql-tag'
import {CONSIGNMENT_MODEL_FRAGMENT} from "@/modules/graphql/ConsignmentModelFragment";
import {STOP_MODEL_FRAGMENT} from "@/modules/graphql/StopModelFragment";
import {VEHICLE_MODEL_FRAGMENT} from "@/modules/graphql/VehicleFragment";

export const LIST_SELF_PLANNING_FRAGMENT = gql`
  ${CONSIGNMENT_MODEL_FRAGMENT}
  ${STOP_MODEL_FRAGMENT}
  ${VEHICLE_MODEL_FRAGMENT}

  fragment PlanningPaginationFields on PlanningPaginationResult {
      items {
        id
        startDate
        identifier
        name
        description
        finished
        created
        updated
        user {
          email
        }
        stops {
          ...StopModelFields
        }
        consignments {
          ...ConsignmentModelFields
        }
        startDate
        vehicles {
          ...VehicleModelFields
        }
        instructions
      }
      total
    }
`
