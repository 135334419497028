import { Component, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import {ListPlannings, ListPlannings_ListPlannings} from '@/generated/ListPlannings'
import { GetPlanning, GetPlanning_GetPlanning } from '@/generated/GetPlanning'
import { ApolloQueryResult } from 'apollo-client'
import { CONSIGNMENT_MODEL_FRAGMENT } from '@/modules/graphql/ConsignmentModelFragment'
import {ListSelfPlannings, ListSelfPlannings_ListSelfPlannings} from "@/generated/ListSelfPlannings";
import {STOP_MODEL_FRAGMENT } from "@/modules/graphql/StopModelFragment";
import { Pagination } from '@/generated/globalTypes'
import {
  SetPlanningLicensePlate,
  SetPlanningLicensePlate_SetPlanningLicensePlate
} from '@/generated/SetPlanningLicensePlate'
import {VEHICLE_MODEL_FRAGMENT} from "@/modules/graphql/VehicleFragment";
import {LIST_SELF_PLANNING_FRAGMENT} from "@/modules/graphql/ListSelfPlanningFragment";
import {ListSelfPlanningsAccessCode} from "@/generated/ListSelfPlanningsAccessCode";
import { GetSelfPlanning, GetSelfPlanning_GetSelfPlanning } from '@/generated/GetSelfPlanning'
import {DriverFinishedPlanning} from "@/generated/DriverFinishedPlanning";
import {DriverFinishedPlanningAccessCode} from "@/generated/DriverFinishedPlanningAccessCode";

@Component
export default class PlanningService extends Vue {
  private static TWO_WEEKS_IN_MILISECONDS = 12096e5

  private listPlannings = gql`
    ${VEHICLE_MODEL_FRAGMENT}
  
    query ListPlannings($pagination: Pagination!, $finished: Boolean!) {
      ListPlannings(a0: $pagination, a1: $finished) {
            items {    
              id
              identifier
              name
              finished        
              created
              updated
        
              user {
                email
              }
              vehicles {
                 ...VehicleModelFields
              }        
            }
          total
      }
    }
  `

  private getSelfPlanningQuery = gql`
    ${CONSIGNMENT_MODEL_FRAGMENT}
    ${STOP_MODEL_FRAGMENT}
    ${VEHICLE_MODEL_FRAGMENT}
    
    query GetSelfPlanning($id: Int!) {
      GetSelfPlanning(a0: $id) {
        id
        updated
        created
        identifier
        name
        finished   
        stops {
          ...StopModelFields
        }
        consignments {
          ...ConsignmentModelFields
        }
        startDate
        vehicles {
          ...VehicleModelFields
        }
        instructions
      }
    }
  `

  private listSelfPlanningsQuery = gql`
    ${LIST_SELF_PLANNING_FRAGMENT}
    
    query ListSelfPlannings($pagination: Pagination!) {
      ListSelfPlannings(a0: $pagination) {
            ...PlanningPaginationFields
      }
    }
  `

  private listSelfPlanningsAccessCodeQuery = gql`
    ${LIST_SELF_PLANNING_FRAGMENT}
    
    query ListSelfPlanningsAccessCode($pagination: Pagination!) {
      ListSelfPlanningsAccessCode(a0: $pagination) {
            ...PlanningPaginationFields
      }
    }
  `

  private setLicensePlateQuery = gql`
    query SetPlanningLicensePlate($id: Int!, $licensePlate: String!) {
      SetPlanningLicensePlate(a0: $id, a1: $licensePlate) {
        id
      }
    }
  `

  private driverFinishedPlanning = gql`
    query DriverFinishedPlanning($id: Int!) {
      DriverFinishedPlanning(a0: $id)
    }
  `

  private driverFinishedPlanningAccessCode = gql`
    query DriverFinishedPlanningAccessCode {
      DriverFinishedPlanningAccessCode
    }
  `

  async getPlanningByIdOrAccessCode(id: number): Promise<GetPlanning_GetPlanning> {
    if (!this.$route.query.accessCode) {
      return this.getSelfPlanning(id)
    } else {
      const plannings = await this.getSelfPlanningOverview(1)
      return Promise.resolve(plannings.items[0])
    }
  }

  setLicensePlate(id: number, licensePlate: string): Promise<SetPlanningLicensePlate_SetPlanningLicensePlate> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.setLicensePlateQuery,
          variables: {
            id: id,
            licensePlate: licensePlate
          }
        })
        .then((result: ApolloQueryResult<SetPlanningLicensePlate>) => {
          resolve(result.data.SetPlanningLicensePlate)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  emitDriverFinishedPlanning(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.$apollo
          .query({
            query: this.$route.query.accessCode ? this.driverFinishedPlanningAccessCode: this.driverFinishedPlanning,
            variables: {
              id: id
            }
          })
          .then((result: ApolloQueryResult<DriverFinishedPlanning | DriverFinishedPlanningAccessCode>) => {
              //@ts-ignore
              resolve(this.$route.query.accessCode ? result.data.DriverFinishedPlanningAccessCode : result.data.DriverFinishedPlanning)
          })
          .catch(reason => {
            reject(reason)
          })
    })
  }

  getPlanningOverview(entityID: number | null = null, pagination: Pagination): Promise<ListPlannings_ListPlannings> {
    return this._getPlanningOverview(false, pagination)
  }
  getFinishedPlanningOverview(entityID: number | null = null, pagination: Pagination): Promise<ListPlannings_ListPlannings> {
    return this._getPlanningOverview(true, pagination)
  }
  private _getPlanningOverview(finished: boolean, pagination: Pagination): Promise<ListPlannings_ListPlannings> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.listPlannings,
          variables: {
            finished: finished,
            pagination: pagination
          }
        })
        .then((result: ApolloQueryResult<ListPlannings>) => {
          resolve(result.data.ListPlannings)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  getSelfPlanningOverview(items: number = 1, page: number = 0, search: string | null = null): Promise<ListSelfPlannings_ListSelfPlannings> {
    return new Promise((resolve, reject) => {
      this.$apollo
          .query({
            query: this.$route.query.accessCode ? this.listSelfPlanningsAccessCodeQuery : this.listSelfPlanningsQuery,
            variables: {
              pagination: {
                itemsPerPage: items,
                page: page,
                search: search,
                  period: {
                    from: new Date(Date.now() - PlanningService.TWO_WEEKS_IN_MILISECONDS).getTime(),
                    to: new Date().getTime()
                  }
              }
            }
          })
          .then((result: ApolloQueryResult<ListSelfPlannings | ListSelfPlanningsAccessCode>) => {
              //@ts-ignore
              resolve(this.$route.query.accessCode ? result.data.ListSelfPlanningsAccessCode : result.data.ListSelfPlannings)
          })
          .catch(reason => {
            reject(reason)
          })
    })
  }

  getSelfPlanning(id: number): Promise<GetSelfPlanning_GetSelfPlanning> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.getSelfPlanningQuery,
          variables: {
            id: id
          }
        })
        .then((result: ApolloQueryResult<GetSelfPlanning>) => {
          resolve(result.data.GetSelfPlanning)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  getPlanning(id: number): Promise<GetPlanning_GetPlanning> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.getPlanningQuery,
          variables: {
            uuid: id
          }
        })
        .then((result: ApolloQueryResult<GetPlanning>) => {
          resolve(result.data.GetPlanning)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  private getPlanningQuery = gql`
    ${CONSIGNMENT_MODEL_FRAGMENT}
    ${STOP_MODEL_FRAGMENT}
    ${VEHICLE_MODEL_FRAGMENT}

    query GetPlanning($uuid: Int!) {
      GetPlanning(a0: $uuid) {
        id
        updated
        created
        identifier
        name
        finished   
        stops {
          ...StopModelFields
        }
        consignments {
          ...ConsignmentModelFields
        }
        startDate
        vehicles {
          ...VehicleModelFields
        }
        instructions
      }
    }
  `
}
